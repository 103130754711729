import React, { Component } from "react";
import Favicon from "react-favicon";
import queryString from "query-string";
import App from "../App";
import DiscontinuedPage from "../DiscontinuedPage";
import { ForbiddenScreen } from "../ErrorScreen";

class DataWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDiscontinued: false,

      selectedAccount: "mysegcause",

      CDDPresent: false,
      CDDUI: "",

      loading: true,

      forbidden: false,

      sessionInfo: {
        bookwellfeeds: {
          account: "bwf",
          gaID: "UA-135605504-1",
          bannersURL: "",
          statesURL: "",
          storesURL: "",
          accountUID: "12F836C9-DC23-4F2A-9838-717B2019DCAD",
          programUID: "B70C3E64-53AB-4D22-A89D-2F9F59D1E45E",
          causeView: "[dbo].[PSIMVW_CDDSBWFCause]",
          variableFields: {
            dateField: false,
            banners: false,
            states: false,
            stores: false,
          },
          singleTag: true,
          zipCodeRequired: true,
        },
        mysegb4g: {
          account: "segb4g",
          gaID: "UA-135605504-1",
          bannersURL: "SEGBanners",
          statesURL: "",
          storesURL: "SEGBannerStores",
          accountUID: "5bf9a4a8-8411-4ebb-8906-37d079c0a1f6",
          programUID: "74DC7749-4946-454C-BE9D-5276C845DDEB",
          causeView: "[dbo].[PSIMVW_CDDSSEGcause]",
          addressTemplate: "${state}, ${city}, ${address}",
          variableFields: {
            dateField: true,
            banners: true,
            states: false,
            stores: true,
          },
        },
        myhannafordcause: {
          account: "hf",
          gaID: "UA-142611659-1",
          bannersURL: "",
          statesURL: "HCStates",
          storesURL: "HCStores",
          accountUID: "779F5862-7EEB-4229-BA69-9BF7E42FE249",
          programUID: "B28671D3-1E1B-484D-9F18-04D29AC27B17",
          causeView: "[dbo].[PSIMVW_CDDSHCcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
          removeSubmission: false,
        },
        mytsmcgivebackbag: {
          account: "tsmc",
          gaID: "UA-142611659-1",
          bannersURL: "SaveMartBanners",
          statesURL: "",
          storesURL: "SaveMartBannerStores",
          accountUID: "32F91392-12B7-463B-BD7F-BCCAC52DFE5C",
          programUID: "432881AB-4A75-4D77-8C74-4CEFE06336A4",
          causeView: "[dbo].[PSIMVW_CDDSSaveMartcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: true,
            states: false,
            stores: true,
          },
          removeSubmission: true,
        },
        "myhy-veecause": {
          account: "hv",
          gaID: "UA-142611659-1",
          bannersURL: "",
          statesURL: "HVStates",
          storesURL: "HVStores",
          accountUID: "BAEE9A25-6325-4B28-88FB-B414C3820805",
          programUID: "D974649F-03D4-4AD9-BCAF-48C881D6ACC5",
          causeView: "[dbo].[PSIMVW_CDDSHVcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mygianteaglecause: {
          account: "ge",
          gaID: "UA-141121583-1",
          bannersURL: "",
          statesURL: "GEStates",
          storesURL: "GEStores",
          accountUID: "36AB9730-B2C6-4802-A858-1E6FFBBB22D4",
          programUID: "AC32F40D-D2E7-4581-B8E4-183C7861663D",
          causeView: "[dbo].[PSIMVW_CDDSGEcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mygiantfoodcause: {
          account: "gf",
          gaID: "UA-138594076-1",
          bannersURL: "",
          statesURL: "GFStates",
          storesURL: "GFStores",
          accountUID: "8023d583-461a-44b7-b501-ff928ec2fc12",
          programUID: "D0A74161-9983-4AFF-85C0-D11164AC07B6",
          causeView: "[dbo].[PSIMVW_CDDSGFcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        myacmecause: {
          account: "acme",
          gaID: "UA-138587928-1",
          bannersURL: "",
          statesURL: "ACMEStates",
          storesURL: "ACMEStores",
          accountUID: "fa3cec4a-c6a4-4a82-96fd-265338ba5ea2",
          programUID: "48A897DF-182F-4306-B0CC-16C808FEBA9E",
          causeView: "[dbo].[PSIMVW_CDDSACMEcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mystopandshopcause: {
          account: "sas",
          gaID: "UA-135648272-1",
          bannersURL: "",
          statesURL: "SASStates",
          storesURL: "SASStores",
          accountUID: "6c729c08-9afd-4e63-a612-c85f6feeca01",
          programUID: "12df4dae-21a3-4863-ace9-e8dd4e696762",
          causeView: "[dbo].[PSIMVW_CDDSSAScause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        myshawscause: {
          account: "shaws",
          gaID: "UA-135618469-1",
          bannersURL: "",
          statesURL: "ShawsStates",
          storesURL: "ShawsStores",
          accountUID: "d4bc08ee-d1f9-408e-9343-f052c190017d",
          programUID: "e2296d42-22db-476a-a170-f56aaf913004",
          causeView: "[dbo].[PSIMVW_CDDSShawscause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mystarmarketcause: {
          account: "stm",
          gaID: "UA-135615823-1",
          bannersURL: "",
          statesURL: "",
          storesURL: "StarMarketStores",
          accountUID: "a745a007-3f62-4543-bf25-6b62e3b9d888",
          programUID: "35189a81-f351-456a-9ac4-ba9a891e0ad3",
          causeView: "[dbo].[PSIMVW_CDDSStarMarketcause]",
          addressTemplate: "${address}, ${city}, ${state}",
          variableFields: {
            dateField: true,
            banners: false,
            states: false,
            stores: true,
          },
        },
        myfreshthymecause: {
          account: "ft",
          gaID: "UA-135614094-1",
          bannersURL: "",
          statesURL: "FTStates",
          storesURL: "FTStores",
          accountUID: "2a00ada5-06e9-49ac-b155-588a0393e0bd",
          programUID: "3caf430b-685d-4e81-b271-8cdcdaedd675",
          causeView: "[dbo].[PSIMVW_CDDSFTcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mybigycause: {
          account: "bigy",
          gaID: "UA-135654479-1",
          bannersURL: "",
          statesURL: "BigYStates",
          storesURL: "BigYStores",
          accountUID: "f8deef48-5fc5-4e8c-802e-af512be3eb46",
          programUID: "635b525c-e223-4278-bdfe-93012a340a02",
          causeView: "[dbo].[PSIMVW_CDDSBYcause]",
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: true,
            banners: false,
            states: true,
            stores: true,
          },
        },
        mygmcause: {
          account: "gm",
          gaID: "UA-135641524-1",
          bannersURL: "GMBanners",
          statesURL: "",
          storesURL: "GMBannerStores",
          accountUID: "7181237a-a8d3-4878-8afa-3a74a0cd5acf",
          programUID: "023bc57c-4519-4bcb-b30e-e23296d05ac6",
          causeView: "[dbo].[PSIMVW_CDDSGMcause]",
          addressTemplate: "${state}, ${city}, ${address}",
          variableFields: {
            dateField: true,
            banners: true,
            states: false,
            stores: true,
          },
        },
        mysegcause: {
          account: "seg",
          gaID: "UA-135605504-1",
          bannersURL: "SEGBanners",
          statesURL: "",
          storesURL: "SEGBannerStores",
          accountUID: "5bf9a4a8-8411-4ebb-8906-37d079c0a1f6",
          programUID: "CAACDB65-A31E-4475-A30D-3D97CE88C073",
          causeView: "[dbo].[PSIMVW_CDDSSEGcause]",
          addressTemplate: "${state}, ${city}, ${address}",
          variableFields: {
            dateField: true,
            banners: true,
            states: false,
            stores: true,
          },
        },
      },
      pageContent: {
        bookwellfeeds: {
          title: "Bags 4 My Cause",
          subTitle: "Bookwell Feeds Program",
          style: {
            primaryColor: "#3498db",
            fontColor: "#3498db",
          },
          contactInfo: {
            tagRedemptionURL: "https://mysegb4g.bags4mycause.com",
            subsiteURL: "http://bloomin4good.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9337",
            email: "seg@bloomin4good.com",
          },
          isBookwell: true,
          customTagLabel: (
            <span>
              Enter the 6-letter code from the email you recieved.{" "}
              <b>There are no numbers.</b>
            </span>
          ),
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>Description of the Bookwell Feeds program</p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to our{" "}
              <a target="_blank" rel="noopener noreferrer" href="/#">
                Privacy Policy
              </a>
            </p>
          ),
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#3498db" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>Focus in their stated mission to impact hunger relief</li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          bottomStatement:
            "We are dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mysegb4g: {
          title: "Bags 4 My Cause",
          subTitle: "Bloomin' 4 Good Program",
          style: {
            primaryColor: "#41946a",
            fontColor: "#41946a",
          },
          contactInfo: {
            tagRedemptionURL: "https://mysegb4g.bags4mycause.com",
            subsiteURL: "http://bloomin4good.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9337",
            email: "seg@bloomin4good.com",
          },
          isSticker: true,
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                Direct the $1 donation generated from the purchase of the{" "}
                <span style={{ fontWeight: 700, color: "#41946a" }}>
                  Bloomin’ 4 Good
                </span>{" "}
                bouquet to the non-profit of your choice. Simply follow the
                3-step instructions on the green sticker attached to the{" "}
                <span style={{ fontWeight: 700, color: "#41946a" }}>
                  Bloomin’ 4 Good
                </span>{" "}
                bouquet to redirect the $1 donation to a non-profit of your
                choice.
              </p>
              <p>
                <em>
                  Please note: The $1 donation from the purchase of the{" "}
                  <span style={{ fontWeight: 700, color: "#41946a" }}>
                    Bloomin’ 4 Good
                  </span>{" "}
                  bouquet will automatically go to the non-profit of the month,
                  featured in the floral section. If you wish to redirect the $1
                  donation to a <strong>different</strong> non-profit, fill out
                  the form below within 7 days of purchase.
                </em>
              </p>
              <p style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "inline-flex",
                    border: "2px solid #f7b844",
                    borderRadius: 4,
                    alignItems: "center",
                    maxWidth: 750,
                    padding: "1rem",
                  }}
                >
                  <svg
                    style={{
                      height: 48,
                      width: 48,
                      color: "#f7b844",
                      flex: "0 0 48px",
                    }}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M489.739 100.174H363.885a66.348 66.348 0 008.984-33.391C372.87 29.959 342.911 0 306.087 0 286.155 0 268.247 8.789 256 22.68 243.753 8.789 225.845 0 205.913 0c-36.824 0-66.783 29.959-66.783 66.783 0 12.16 3.282 23.56 8.984 33.391H22.261c-9.22 0-16.696 7.475-16.696 16.696v100.174c0 9.22 7.475 16.696 16.696 16.696h16.696v261.565c0 9.22 7.475 16.696 16.696 16.696H456.35c9.22 0 16.696-7.475 16.696-16.696V233.739h16.696c9.22 0 16.696-7.475 16.696-16.696V116.87c-.003-9.221-7.478-16.696-16.699-16.696zM205.913 478.609H72.348v-244.87h133.565v244.87zm0-278.261H38.957v-66.783h166.956v66.783zm0-100.174c-18.412 0-33.391-14.979-33.391-33.391s14.979-33.391 33.391-33.391 33.391 14.979 33.391 33.391v33.391H205.913zm66.783 378.435h-33.391v-244.87h33.391v244.87zm0-278.261h-33.391v-66.783H272.696v66.783zm0-100.174V66.783c0-18.412 14.979-33.391 33.391-33.391s33.391 14.979 33.391 33.391-14.979 33.391-33.391 33.391H272.696zm166.956 378.435H306.087v-244.87h133.565v244.87zm33.391-278.261H306.087v-66.783h166.956v66.783z"
                    />
                  </svg>
                  <span style={{ display: "inline-block", marginLeft: 16 }}>
                    If you received this bouquet as a gift, the $1 donation has
                    already been directed, and the code on your sticker is no
                    longer valid.
                  </span>
                </div>
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:seg@bloomin4good.com">seg@bloomin4good.com</a>.
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.segrocers.com/privacypolicy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#41946a" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific BI-LO, Fresco y Más,
                  Harveys or Winn-Dixie store.
                </li>
                <li>
                  The commitment to put funds to use within the local BI-LO,
                  Fresco y Más, Harveys or Winn-Dixie store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, disaster
                  relief, or military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          bottomStatement:
            "We are dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        myhannafordcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#e51419",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://myhannafordcause.bags4mycause.com",
            subsiteURL: "https://hannaford.bags4mycause.com/",
            programManager: "Jim Brennan",
            phone: "(603) 380-9339",
            email: "hannaford@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of
                either the Community Bag or the Fight Hunger Bag to the
                non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:hannaford@bags4mycause.com">
                  hannaford@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Hannaford's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hannaford.com/customer-service/privacy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Hannaford Supermarkets has a long history of supporting the people and communities which we serve.  We appreciate the many non-profits throughout these local communities that are working hard to keep them healthy.",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#e51419" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Hannaford store.
                </li>
                <li>
                  Non-profits that are clearly focused in their stated mission
                  to impact hunger relief, education (all aspects), health &
                  wellness, civic, or military/veterans organizations
                </li>
                <li>
                  Organizations that provide for the physical, emotional,
                  educational and cultural enhancement and well-being of
                  children
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers limited to specific individuals or families
                </li>
                <li>International Organizations</li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community
                </li>
                <li>
                  Requests to support expenses related to travel or conferences
                  for employees of non-profit organizations
                </li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>Private clubs, such as rotary clubs</li>
                <li>
                  Requests for support of organizations or programs that
                  discriminate on the basis of race, color, religion, sex
                  (including gender identity & sexual orientation), national
                  origin, age, or disability.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mytsmcgivebackbag: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#eb3a43",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://mytsmcgivebackbag.bags4mycause.com",
            subsiteURL: "https://tsmcgivebackbag.bags4mycause.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9347",
            email: "savemart@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Give Back Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:savemart@bags4mycause.com">
                  savemart@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Save Mart's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.savemart.com/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "The Save Mart Companies is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#eb3a43" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Save Mart, Lucky,
                  Lucky California, or FoodMaxx store.
                </li>
                <li>
                  Non-profits that are clearly focused in their stated mission
                  to impact hunger relief, education (all aspects), health &
                  wellness, civic, or military/veterans organizations
                </li>
                <li>
                  Organizations that provide for the physical, emotional,
                  educational and cultural enhancement and well-being of
                  children
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers limited to specific individuals or families
                </li>
                <li>International Organizations</li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community
                </li>
                <li>
                  Requests to support expenses related to travel or conferences
                  for employees of non-profit organizations
                </li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>Private clubs, such as rotary clubs</li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        "myhy-veecause": {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#E12319",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://myhyveecause.bags4mycause.com",
            subsiteURL: "https://hyvee.bags4mycause.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9346",
            email: "hy-vee@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                reusable bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:hy-vee@bags4mycause.com">
                  hy-vee@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Hy-Vee's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hy-vee.com/corporate/policy/privacy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Hy-Vee is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#E12319" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>A direct local connection to a specific Hy-Vee store.</li>
                <li>
                  Non-profits that are clearly focused in their stated mission
                  to impact hunger relief, education (all aspects), health &
                  wellness, civic, or military/veterans organizations
                </li>
                <li>
                  Organizations that provide for the physical, emotional,
                  educational and cultural enhancement and well-being of
                  children
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers limited to specific individuals or families
                </li>
                <li>International Organizations</li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community
                </li>
                <li>
                  Requests to support expenses related to travel or conferences
                  for employees of non-profit organizations
                </li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>Private clubs, such as rotary clubs</li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mygianteaglecause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#E13A3D",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://mygianteaglecause.bags4mycause.com",
            subsiteURL: "https://gianteagle.bags4mycause.com/",
            programManager: "Sarah Scott",
            phone: "(603) 380-9345",
            email: "gianteagle@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Community Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:gianteagle@bags4mycause.com">
                  gianteagle@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Giant Eagle's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://account.gianteagle.com/PrivacyPolicy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Giant Eagle is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#E13A3D" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Giant Eagle store.
                </li>
                <li>
                  The commitment to put funds to use within the Giant Eagle
                  store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mygiantfoodcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#6A216A",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://mygiantfoodcause.bags4mycause.com",
            subsiteURL: "https://giantfood.bags4mycause.com/",
            programManager: "Sarah Scott",
            phone: "(603) 380-9342",
            email: "giantfood@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Community Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:giantfood@bags4mycause.com">
                  giantfood@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Giant Food's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://giantfood.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Giant Food is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#6A216A" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Giant Food store.
                </li>
                <li>
                  The commitment to put funds to use within the Giant Food
                  store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        myacmecause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#EC403C",
            secondaryColor: "",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://myacmecause.bags4mycause.com",
            subsiteURL: "https://acme.bags4mycause.com/",
            programManager: "Jim Brennan",
            phone: "(603) 380-9343",
            email: "acme@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                GIVE BACK WHERE IT COUNTS Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:acme@bags4mycause.com">acme@bags4mycause.com</a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to ACME's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.acmemarkets.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "ACME is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#EC403C" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>A direct local connection to a specific ACME store.</li>
                <li>
                  The commitment to put funds to use within the ACME store’s
                  community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mystopandshopcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#63666A",
            secondaryColor: "#8BC400",
            tertiaryColor: "#E0004D",
            font: "Effra",
          },
          contactInfo: {
            tagRedemptionURL: "https://mystopandshopcause.bags4mycause.com",
            subsiteURL: "https://stopandshop.bags4mycause.com/",
            programManager: "Sarah Scott",
            phone: "(603) 380-9335",
            email: "stopandshop@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Community Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:stopandshop@bags4mycause.com">
                  stopandshop@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Stop and Shop's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://stopandshop.com/pages/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "We are dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#E0004D" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Stop & Shop store.
                </li>
                <li>
                  The commitment to put funds to use within the Stop & Shop
                  store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        myshawscause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#F58426",
            secondaryColor: "#7AC142",
            tertiaryColor: "#C3CF21",
          },
          contactInfo: {
            tagRedemptionURL: "https://myshawscause.bags4mycause.com",
            subsiteURL: "https://shaws.bags4mycause.com/",
            programManager: "Jim Brennan",
            phone: "(603) 380-9336",
            email: "shaws@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Give Back Where it Counts Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:shaws@bags4mycause.com">
                  shaws@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Shaw's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.shaws.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Shaw's is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#7AC142" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>A direct local connection to a specific Shaw's store.</li>
                <li>
                  The commitment to put funds to use within the Shaw's store’s
                  community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mystarmarketcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#017AC3",
            secondaryColor: "#7AC142",
          },
          contactInfo: {
            tagRedemptionURL: "https://mystarmarketcause.bags4mycause.com",
            subsiteURL: "https://starmarket.bags4mycause.com/",
            programManager: "Jim Brennan",
            phone: "(603) 380-9336",
            email: "starmarket@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Give Back Where it Counts Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:starmarket@bags4mycause.com">
                  starmarket@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Star Market's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.starmarket.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Star Market is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#017AC3" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Star Market store.
                </li>
                <li>
                  The commitment to put funds to use within the Star Market
                  store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        myfreshthymecause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#00853F",
            secondaryColor: "#FFD661",
          },
          contactInfo: {
            tagRedemptionURL: "https://myfreshthymecause.bags4mycause.com",
            subsiteURL: "https://freshthyme.bags4mycause.com/",
            programManager: "Danielle Nonamaker",
            phone: "(603) 380-9340",
            email: "freshthyme@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Giving Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:freshthyme@bags4mycause.com">
                  freshthyme@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Fresh Thyme
              Market's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.freshthyme.com/mythyme-privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "At Fresh Thyme Farmers Market, we believe in cultivating our relationships with local organizations, schools and non-profits so together we can make a difference in our communities. Thank you for joining us in these efforts!",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#00853F" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Fresh Thyme store.
                </li>
                <li>
                  The commitment to put funds to use within the Fresh Thyme
                  store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mybigycause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#D22449",
          },
          contactInfo: {
            tagRedemptionURL: "https://mybigycause.bags4mycause.com",
            subsiteURL: "https://bigy.bags4mycause.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9338",
            email: "bigy@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Community Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:bigy@bags4mycause.com">bigy@bags4mycause.com</a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to Big Y's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bigy.com/Services/Policies/PrivacyPolicy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#D22449" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>A direct local connection to a specific Big Y store.</li>
                <li>
                  The commitment to put funds to use within the Big Y store’s
                  community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, or
                  military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>
                  Religious organizations (except where a food pantry is
                  involved).
                </li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          bottomStatement:
            "Big Y is dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
        mygmcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#E5343D",
          },
          contactInfo: {
            tagRedemptionURL: "https://mygmcause.bags4mycause.com",
            subsiteURL: "https://giant.bags4mycause.com/",
            programManager: "Sarah Scott",
            phone: "(603) 380-9341",
            email: "giantmartins@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Bags 4 My Cause Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:giantmartins@bags4mycause.com">
                  giantmartins@bags4mycause.com
                </a>
                .
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://giantfoodstores.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </p>
          ),
          criteria: {
            "MARTIN'S": (
              <div className="requirements">
                <h3 style={{ color: "#E5343D" }}>
                  Non-Profit Criteria for the Program
                </h3>
                <p>
                  The purpose of this program is to support the non-profits in
                  your community that are important to you. To be considered for
                  a donation, organizations must have:
                </p>
                <ul>
                  <li>501(c)(3) status.</li>
                  <li>A direct local connection to a MARTIN'S store.</li>
                  <li>
                    The commitment to put funds to use within the MARTIN's
                    store’s community.
                  </li>
                  <li>
                    Focus in their stated mission to impact hunger relief,
                    education (all aspects), health & wellness, civic, or
                    military/veterans organizations.
                  </li>
                </ul>
                <p>
                  Requests That Will <b>Not</b> Qualify:
                </p>
                <ul>
                  <li>
                    Events/Fundraisers/Services that benefit a for-profit
                    business.
                  </li>
                  <li>
                    Events/Fundraisers limited to specific individuals or
                    families.
                  </li>
                  <li>Political organizations or lobbying groups.</li>
                  <li>
                    Religious organizations (except where a food pantry is
                    involved).
                  </li>
                  <li>Private clubs.</li>
                  <li>
                    Requests to support expenses related to fundraisers/events.
                  </li>
                  <li>
                    Any organization that discriminates on the basis of gender,
                    race, ethnicity, religion and/or sexual orientation.
                  </li>
                  <li>
                    Any organization that does not put 100% of the donations to
                    supporting the local community.
                  </li>
                </ul>
              </div>
            ),
            GIANT: (
              <div className="requirements">
                <h3 style={{ color: "#E5343D" }}>
                  Non-Profit Criteria for the Program
                </h3>
                <p>
                  The purpose of this program is to support the non-profits in
                  your community that are important to you. To be considered for
                  a donation, organizations must have:
                </p>
                <ul>
                  <li>501(c)(3) status.</li>
                  <li>A direct local connection to a GIANT store.</li>
                  <li>
                    The commitment to put funds to use within the GIANT store’s
                    community.
                  </li>
                  <li>
                    Focus in their stated mission to impact hunger relief,
                    education (all aspects), health & wellness, civic, or
                    military/veterans organizations.
                  </li>
                </ul>
                <p>
                  Requests That Will <b>Not</b> Qualify:
                </p>
                <ul>
                  <li>
                    Events/Fundraisers/Services that benefit a for-profit
                    business.
                  </li>
                  <li>
                    Events/Fundraisers limited to specific individuals or
                    families.
                  </li>
                  <li>Political organizations or lobbying groups.</li>
                  <li>
                    Religious organizations (except where a food pantry is
                    involved).
                  </li>
                  <li>Private clubs.</li>
                  <li>
                    Requests to support expenses related to fundraisers/events.
                  </li>
                  <li>
                    Any organization that discriminates on the basis of gender,
                    race, ethnicity, religion and/or sexual orientation.
                  </li>
                  <li>
                    Any organization that does not put 100% of the donations to
                    supporting the local community.
                  </li>
                </ul>
              </div>
            ),
          },
          bottomStatement:
            "We are dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },

        mysegcause: {
          title: "Bags 4 My Cause",
          subTitle: "Giving Tag Program",
          style: {
            primaryColor: "#2B658F",
          },
          contactInfo: {
            tagRedemptionURL: "https://mysegcause.bags4mycause.com",
            subsiteURL: "https://seg.bags4mycause.com/",
            programManager: "Kim Monaco",
            phone: "(603) 380-9337",
            email: "seg@bags4mycause.com",
          },
          introParagraphs: (
            <div className="intro-paragraphs">
              <p>
                With the Giving Tag, you can support the community you call home
                by directing your $1 donation generated from the purchase of the
                Community Bag to the non-profit of your choice.
              </p>
              <p>
                Just fill out the following form and you will be able to select
                the non-profit you would like to support!
              </p>
              <p>
                If you have any questions, please contact us directly at{" "}
                <a href="mailto:seg@bags4mycause.com">seg@bags4mycause.com</a>.
              </p>
            </div>
          ),
          privacy: (
            <p>
              All information provided will strictly adhere to our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.segrocers.com/privacypolicy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#2B658F" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific BI-LO, Fresco y Más,
                  Harveys or Winn-Dixie store.
                </li>
                <li>
                  The commitment to put funds to use within the local BI-LO,
                  Fresco y Más, Harveys or Winn-Dixie store’s community.
                </li>
                <li>
                  Focus in their stated mission to impact hunger relief,
                  education (all aspects), health & wellness, civic, disaster
                  relief, or military/veterans organizations.
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers/Services that benefit a for-profit
                  business.
                </li>
                <li>
                  Events/Fundraisers limited to specific individuals or
                  families.
                </li>
                <li>Political organizations or lobbying groups.</li>
                <li>Private clubs.</li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation.
                </li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community.
                </li>
              </ul>
            </div>
          ),
          bottomStatement:
            "We are dedicated to supporting the communities we serve and the non-profits that impact our customers. Thank you for joining us in these efforts!",
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              "-",
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "___-___",
          },
        },
      },
    };
  }

  componentDidMount() {
    const { sessionInfo } = this.state;

    const hostnameArr = window.location.hostname.split(".");

    const params = queryString.parse(window.location.search);

    if (window.location.hostname.includes("mytestcause")) {
      if (!(params.code && params.code === "68310824")) {
        this.setState({ forbidden: true });
        return;
      }
    }

    if ("CDDUI" in params) {
      this.setState({
        CDDUI: params.CDDUI,
        CDDPresent: true,
      });
    }

    const subdomain = hostnameArr[0].toLowerCase();

    const accountList = Object.keys(sessionInfo);

    let account = "";

    if (hostnameArr[0]?.toLowerCase() === "bookwellfeeds") {
      account = "bookwellfeeds";
    } else if (accountList.includes(subdomain)) {
      account = subdomain;
    } else if ("test" in params) {
      accountList.forEach((acc) => {
        console.log(acc);
        console.log(sessionInfo[acc].account);
        if (sessionInfo[acc].account === params.test.toLowerCase()) {
          account = acc;
        }
      });
    } else {
      console.log("forbidden because of subdomain", subdomain);
      this.setState({ forbidden: true });
      return;
    }

    const discontinuedAccounts = ["mygmcause"];

    if (discontinuedAccounts.includes(account)) {
      this.setState({ isDiscontinued: true });
    }

    console.log(account);

    this.setState((prevState) => ({
      loading: false,
      selectedAccount: account,
      faviconURL: `${process.env.PUBLIC_URL}images/${prevState.sessionInfo[account].account}/favicons/favicon.ico`,
    }));
  }

  cancelCDD = () => {
    this.setState({
      CDDUI: "",
      CDDPresent: false,
    });
  };

  render() {
    const {
      forbidden,
      selectedAccount,
      loading,
      faviconURL,
      CDDUI,
      CDDPresent,
      sessionInfo,
      pageContent,
    } = this.state;

    if (forbidden) {
      return <ForbiddenScreen />;
    }

    if (this.state.isDiscontinued) {
      return (
        <div>
          <Favicon url={faviconURL} />
          <DiscontinuedPage
            CDDUI={CDDUI}
            CDDPresent={CDDPresent}
            selectedAccount={selectedAccount}
            sessionInfo={sessionInfo[selectedAccount]}
            pageContent={pageContent[selectedAccount]}
            cancelCDD={this.cancelCDD}
          />
        </div>
      );
    }

    if (selectedAccount && !loading) {
      return (
        <div>
          <Favicon url={faviconURL} />
          <App
            CDDUI={CDDUI}
            CDDPresent={CDDPresent}
            selectedAccount={selectedAccount}
            sessionInfo={sessionInfo[selectedAccount]}
            pageContent={pageContent[selectedAccount]}
            cancelCDD={this.cancelCDD}
          />
        </div>
      );
    }
    return <div />;
  }
}

export default DataWrapper;
