import React, { Component } from "react";
import "./ErrorScreen.scss";

class ErrorScreen extends Component {
  render() {
    return (
      <div className="error-screen">
        <h3>Something went wrong!</h3>
        {this.props.errorState.isOutdatedBrowser ? (
          <div>
            <p>Your Web Browser is too old to view this page</p>
            <p>
              <a
                href="https://www.whatismybrowser.com/guides/how-to-update-your-browser/auto"
                target="_blank"
                rel="noopener noreferrer"
              >
                Update your browser by clicking here
              </a>
            </p>
          </div>
        ) : (
          <p>Please reload the page and try again</p>
        )}

        <p>-or-</p>
        <p>Contact our Program Manager directly</p>
        <p>{this.props.contactInfo.programManager}</p>
        <p>{this.props.contactInfo.phone}</p>
        <p>
          <a href={`mailto:${  this.props.contactInfo.email  }?subject=Tag Redemption Issues:`}>
            {this.props.contactInfo.email}
          </a>
        </p>
      </div>
    );
  }
}

export default ErrorScreen;
