import { polyfill } from "es6-promise";

polyfill();

const axios = require("axios");

const baseURL = window.location.hostname.toLowerCase().includes("localhost")
  ? "https://mybigycause.bags4mycause.com/api"
  : "./api";

export default axios.create({ baseURL });
