import React, { Component } from "react";
import "./ErrorScreen.scss";

class ForbiddenScreen extends Component {
  render() {
    return (
      <div className="error-screen">
        <h3>Forbidden</h3>
        <p>You do not have permission to access this page</p>
      </div>
    );
  }
}

export default ForbiddenScreen;
