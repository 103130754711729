import React from "react";
import AlertIcon from "../Icons/AlertIcon";
import "./Toast.scss";

const Home = ({ hidden, update, text }) => (
  <div className={`toast-notification ${hidden === true ? "hidden" : ""}`}>
    <div className="toast-icon">
      <AlertIcon className="toast-icon" />
    </div>
    <div className={`toast-text${update ? " update" : ""}`}>{text}</div>
  </div>
);

export default Home;
