import React from "react";
import "./ThankYou.scss";

const TagField = ({ isNewCause, selectedCause, loginResponse, title }) => (
  <div className="thank-you-page">
    <h2>Thank You!</h2>
    <p>
      Thank you for {isNewCause ? "submitting" : "selecting"}{" "}
      <b>{selectedCause.CauseName}</b> to receive a{" "}
      {loginResponse.DonationFormatted} donation through the {title}.
    </p>
    {isNewCause && (
      <p>
        If there is an issue with the non-profit you proposed, we will contact
        you within 48 hours. Otherwise, your non-profit will be added to the
        program and receive your direct donation.
      </p>
    )}
    <p>Together we can make a real impact on the communities we call home!</p>
  </div>
);

export default TagField;
