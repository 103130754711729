import React, { Component } from "react";
import "./Header.scss";

class Header extends Component {
  render() {
    return (
      <header
        style={{ backgroundColor: this.props.pageContent.style.primaryColor }}
      >
        <section className="header-content">
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={this.props.pageContent.contactInfo.tagRedemptionURL}
          >
            <h1>{this.props.pageContent.subTitle}</h1>
          </a>
        </section>
      </header>
    );
  }
}

export default Header;
