import React, { Component } from "react";
import Select from "react-select";
import queryString from "query-string";
import states from "../../lib/states";
import PlusIcon from '../Icons/PlusIcon';

import "./CauseForm.scss";

const stateArr = states.map(state => ({
  label: `${state.name} (${state.abbreviation})`,
  value: state.abbreviation
}));

class CauseForm extends Component {
  componentDidMount = () => {
    const params = queryString.parse(window.location.search);
    if ("mode" in params && params.mode.toLowerCase() === "test") {
      this.props.updateSelectedState(stateArr[0]);
    }
  }

  render() {
    return (
      <div id="cause-form" className={this.props.formOpen ? "" : "form-hidden"}>
        <section className="header-section" onClick={this.props.toggleForm} name="new-cause-header">
          <h2
            style={{ color: this.props.pageContent.style.primaryColor }}
            className="submission-header"
          >
            Add Your Non-Profit
          </h2>
          <PlusIcon
            color={this.props.pageContent.style.primaryColor}
          />
        </section>

        <form noValidate>
          <section className="form-content">
            <section className="form-top-section">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    Your Name <b>*</b>
                  </label>
                  <input
                    name="UserName"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.UserName}
                    type="text"
                    className="form-control"
                    placeholder="Your full name"
                    required
                  />
                  
                </div>
                <div className="form-group col-md-6">
                  <label>
                    Your Email <b>*</b>
                  </label>
                  <input
                    name="UserEmail"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.UserEmail}
                    type="email"
                    className="form-control"
                    placeholder="Your email address"
                    required
                  />
                  <small>We will only contact you if there is an issue with your non-profit</small>
                </div>
              </div>
            </section>
            <section className="form-bottom-section">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    Non-Profit Name <b>*</b>
                  </label>
                  <input
                    name="CauseName"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseName}
                    type="text"
                    className="form-control"
                    placeholder="Non-Profit Name"
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>
                    Category <b>*</b>
                  </label>
                  <Select
                    value={this.props.causeSubmissionForm.CauseCategoryObj}
                    placeholder="Select..."
                    onChange={this.props.updateSelectedCategory}
                    options={this.props.categories}
                    isSearchable={false}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>
                  Address <b>*</b>
                </label>
                <input
                  name="CauseAddress1"
                  onChange={this.props.updateCauseSubmissionForm}
                  value={this.props.causeSubmissionForm.CauseAddress1}
                  type="text"
                  className="form-control"
                  placeholder="1234 Main St"
                  style={{ marginBottom: ".5rem" }}
                  required
                />
                <input
                  name="CauseAddress2"
                  onChange={this.props.updateCauseSubmissionForm}
                  value={this.props.causeSubmissionForm.CauseAddress2}
                  type="text"
                  className="form-control"
                  placeholder="Apartment, studio, or floor"
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>
                    City <b>*</b>
                  </label>
                  <input
                    name="CauseCity"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseCity}
                    type="text"
                    className="form-control"
                    placeholder="City"
                    required
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>
                    State <b>*</b>
                  </label>
                  <Select
                    value={this.props.causeSubmissionForm.CauseStateObj}
                    placeholder="Select..."
                    onChange={this.props.updateSelectedState}
                    options={stateArr}
                    required
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>
                    Zip Code <b>*</b>
                  </label>
                  <input
                    name="CauseZip"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseZip}
                    type="text"
                    className="form-control"
                    placeholder="00000"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Phone Number</label>
                  <input
                    name="CausePhone"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CausePhone}
                    type="text"
                    className="form-control"
                    placeholder="000-000-0000"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Website</label>
                  <input
                    name="CauseWebsite"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseWebsite}
                    type="text"
                    className="form-control"
                    placeholder="www.example.com"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Contact First Name</label>
                  <input
                    name="CauseFirstName"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseFirstName}
                    type="text"
                    className="form-control"
                    placeholder="John"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Contact Last Name</label>
                  <input
                    name="CauseLastName"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseLastName}
                    type="text"
                    className="form-control"
                    placeholder="Smith"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Contact Title</label>
                  <input
                    name="CauseTitle"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseTitle}
                    type="text"
                    className="form-control"
                    placeholder="Treasurer"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    name="CauseEmail"
                    onChange={this.props.updateCauseSubmissionForm}
                    value={this.props.causeSubmissionForm.CauseEmail}
                    type="email"
                    className="form-control"
                    placeholder="john.smith@example.com"
                  />
                </div>
              </div>
              <div className="button-container">
                <button
                  onClick={this.props.handleNewCauseSubmit}
                  type="submit"
                  className={`btn btn-lg btn-${  this.props.sessionInfo.account}`}
                  name="submit-new-cause-button"
                >
                  Submit Non-Profit
                </button>
              </div>
            </section>
          </section>
        </form>
      </div>
    );
  }
}

export default CauseForm;
