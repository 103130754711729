export const isEmail = email => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export const isZipCode = zipcode => {
  return Number.isInteger(Number(zipcode)) && zipcode.length === 5;
}

export const isName = name => {
  return name.length !== 0;
}

export const isDate = date => {
  if (Date.parse(date)) {
    return true;
  } 
    return false;
  
}

export const doTagsExist = tags => {
  return tags.length > 0;
}

export const isSelected = selection => {
  return selection && selection.toString().length > 0;
}

export const resetScroll = () => {
  window.scroll(0, 0);
}

export const getDateString = date => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1; // January is 0!
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${  dd}`;
  }
  if (mm < 10) {
    mm = `0${  mm}`;
  }
  return `${mm  }/${  dd  }/${  yyyy}`;
}