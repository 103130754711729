import React, { Component } from "react";
import API from "../API";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import CauseForm from "../CauseForm";

import "./Selection.scss";

import queryString from "query-string";

export default class Selection extends Component {
  constructor() {
    super();

    this.state = {
      formOpen: false,
      categories: [],
      criteria: <div />,
    };

    this.fetchData = this.fetchData.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  componentDidMount() {
    this.getCauseCategories();

    if (this.props.sessionInfo.account === "gm") {
      this.setState({
        criteria: this.props.pageContent.criteria[this.props.selectedChain],
      });
    } else {
      this.setState({
        criteria: this.props.pageContent.criteria,
      });
    }
  }

  toggleForm() {
    this.setState({
      formOpen: !this.state.formOpen,
    });
  }

  fetchData(state) {
    this.props.updateTable(state.pageSize, state.page, state.sorted);
  }

  getCauseCategories() {
    API.get("/Cause/GetCauseCategories", {
      params: {
        accountUID: this.props.sessionInfo.accountUID,
        companyUID: this.props.loginResponse.PurchaseLocation,
      },
    })
      .then((res) => {
        const newState = {
          categories: res.data.Records.map((record) => {
            return {
              value: record.CategoryID,
              label: record.Category,
            };
          }).filter((category) => category.value !== 0),
        };
        const params = queryString.parse(window.location.search);
        if ("mode" in params && params.mode.toLowerCase() === "test") {
          this.props.updateSelectedCategory(newState.categories[0]);
        }
        this.setState(newState);
      })
      .catch((error) => {
        this.setState({
          errorHasOccured: true,
        });
      });
  }

  render() {
    let columns = [];
    if (this.props.screenSize === "large") {
      columns = [
        {
          Header: "Non-Profit",
          id: "CauseName",
          accessor: "CauseName",
        },
        {
          Header: "Category",
          accessor: "CauseCategory",
          maxWidth: 180,
        },
        {
          Header: "Location",
          accessor: "CauseLocation",
          maxWidth: 180,
        },
        {
          Header: "Distance (m)",
          accessor: "CauseDistance",
          maxWidth: 150,
          Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
        },
      ];
    } else if (this.props.screenSize === "medium") {
      columns = [
        {
          Header: "Non-Profit",
          id: "CauseName",
          accessor: "CauseName",
        },
        {
          Header: "Location",
          accessor: "CauseLocation",
        },
      ];
    } else {
      columns = [
        {
          Header: "Non-Profit",
          id: "CauseName",
          accessor: (d) => [d.CauseName, d.CauseLocation, d.CauseDistance],
          Cell: (row) => (
            <div>
              <div>{row.value[0]}</div>
              <div>{`${row.value[1]} - ${row.value[2]} miles away`}</div>
            </div>
          ),
        },
      ];
    }

    return (
      <div className="selection-page">
        <div className="top-section">
          <section className="title-section">
            {this.props.pageContent.isBookwell ? (
              <h4>
                You have {this.props.loginResponse.DonationFormatted} available
                to donate from your stay at {this.props.loginResponse.Purchase}
              </h4>
            ) : (
              <h4>
                You have {this.props.loginResponse.DonationFormatted} available
                to donate to the non-profit of your choice!
              </h4>
            )}
          </section>
          {this.props.CDDPresent ? (
            <p>
              Upon review, we found that the non-profit you submitted did not
              meet all of the requirements for this program. Please either
              submit a different non-profit or choose one from our list. If you
              choose to add a new non-profit, please read the list of
              requirements at the bottom of the page before submitting.
            </p>
          ) : this.props.sessionInfo.removeSubmission ? (
            <div>
              <p>
                {/* Search this table to find a non-profit you would like to
                redirect your donation to! */}
              </p>
            </div>
          ) : (
            <div>
              <p>
                If you do not see the non-profit that you want to benefit, you
                can submit it by filling out the form below. Please read all the
                requirements for qualifying non-profits before submitting.
              </p>
              <p>
                If you submit a new non-profit, you will be notified within 48
                hours if there is an issue with the submission, otherwise your
                non-profit will be included in the program and will receive your
                direct donation.
              </p>
            </div>
          )}
        </div>
        <div
          className="table-section"
          style={{ backgroundColor: this.props.pageContent.style.primaryColor }}
        >
          <div className="table-controls">
            <div className="form-group">
              <label htmlFor="causeSearch">Search Non-Profits</label>
              <input
                type="text"
                className="form-control"
                id="causeSearch"
                aria-describedby="causeSearch"
                placeholder="Enter Non-Profit Name"
                value={this.props.causeSearchTerm}
                onChange={this.props.onSearchChange}
              />
            </div>
            <div className="form-group">
              <label>
                {this.props.distanceDisabled
                  ? "Any distance from "
                  : `Within ${this.props.causeDistance} miles of `}
                {this.props.loginResponse.ZipCode}
                {this.props.isStoreZip && " (Store Zip)"}
              </label>
              <Slider
                min={10}
                max={200}
                value={this.props.causeDistance}
                onChange={this.props.distanceUpdated}
                step={10}
                disabled={this.props.distanceDisabled}
              />
            </div>
          </div>
          <ReactTable
            columns={columns}
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={this.props.data}
            showPaginationTop
            showPaginationBottom={false}
            pages={this.props.numPages} // Display the total number of pages
            loading={this.props.loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            pageSize={this.props.pageSize}
            showPageSizeOptions={false}
            showPageJump={false}
            className="-striped -highlight"
            resizable={false}
            page={this.props.pageNum}
            onPageChange={this.props.handlePageChange}
            getTrProps={this.props.handleCauseClick}
          />
        </div>
        {!this.props.sessionInfo.removeSubmission && (
          <>
            <CauseForm
              pageContent={this.props.pageContent}
              updateCauseSubmissionForm={this.props.updateCauseSubmissionForm}
              causeSubmissionForm={this.props.causeSubmissionForm}
              updateSelectedState={this.props.updateSelectedState}
              updateSelectedCategory={this.props.updateSelectedCategory}
              handleNewCauseSubmit={this.props.handleNewCauseSubmit}
              toggleForm={this.toggleForm}
              formOpen={this.state.formOpen}
              categories={this.state.categories}
              shouldHideFields={this.state.shouldHideFields}
              sessionInfo={this.props.sessionInfo}
            />

            {this.state.criteria}
          </>
        )}
      </div>
    );
  }
}
