import React, { Component } from "react";
import "./ConfirmationModal.scss";

export default class ConfirmationModal extends Component {
  render() {
    return (
      <div className={`confirmation-modal-page ${  this.props.hidden && "hidden"}`}>
        <div className="confirmation-modal-window">
          {this.props.loginResponse && (
            <h4>{`Confirm that you would like to donate ${this.props.loginResponse.DonationFormatted} to the following:`}</h4>
          )}
          {this.props.content && (
            <div className="confirmation-modal-window-content">
              <div className="flex-row">
                <div className="row-name">Non-Profit:</div>
                <div className="row-content">{this.props.content.CauseName}</div>
              </div>
              <div className="flex-row">
                <div className="row-name">Location:</div>
                <div className="row-content">{this.props.content.CauseLocation}</div>
              </div>
              <div className="flex-row">
                <div className="row-name">Distance Away:</div>
                <div className="row-content">{this.props.content.CauseDistance} miles</div>
              </div>
              <div className="flex-row">
                <div className="row-name">Category:</div>
                <div className="row-content">{this.props.content.CauseCategory}</div>
              </div>
            </div>
          )}
            
            
            <div className="button-section">
              <button onClick={this.props.closeModal} className="btn btn-danger">Cancel</button>
              <button onClick={this.props.handleCauseSubmit} className="btn btn-success" name="final-submit-button">Submit</button>
            </div>
          </div>
      </div>
    );
  }
}
