import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./DiscontinuedPage.scss";

const DiscontinuedPage = ({ pageContent, sessionInfo }) => {
  return (
    <article className="discontinued-page">
      <Header pageContent={pageContent} sessionInfo={sessionInfo} />
      <div className="discontinued-page-content">
        <h2>This program has been discontinued</h2>
      </div>
      <Footer pageContent={pageContent} sessionInfo={sessionInfo} />
    </article>
  );
};

export default DiscontinuedPage;
