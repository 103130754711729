import React, { Component } from "react";
import "./App.scss";
import "./Buttons.scss";
import _ from "lodash";
import ReactGA from "react-ga";
import queryString from "query-string";
import Home from "../Home";
import Selection from "../Selection";
import Header from "../Header";
import Footer from "../Footer";
import API from "../API";
import Toast from "../Toast";
import Modal from "../Modal";
import ConfirmationModal from "../ConfirmationModal";
import LoadingScreen from "../LoadingScreen";
import ThankYou from "../ThankYou";
import ErrorScreen from "../ErrorScreen";

import {
  isEmail,
  isZipCode,
  isName,
  isDate,
  isSelected,
  resetScroll,
  getDateString,
} from "../../lib/functions";

let testMode = false;

class App extends Component {
  constructor() {
    super();

    this.onBannerSelect = this.onBannerSelect.bind(this);
    this.onStoreSelect = this.onStoreSelect.bind(this);
    this.onStateSelect = this.onStateSelect.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onZipCodeChange = this.onZipCodeChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.getCauseList = this.getCauseList.bind(this);
    this.distanceUpdated = this.distanceUpdated.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCauseClick = this.handleCauseClick.bind(this);
    this.handleCauseSubmit = this.handleCauseSubmit.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.handleTagInputChange = this.handleTagInputChange.bind(this);
    this.openToast = this.openToast.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.updateCauseSubmissionForm = this.updateCauseSubmissionForm.bind(this);
    this.updateSelectedCategory = this.updateSelectedCategory.bind(this);
    this.updateSelectedState = this.updateSelectedState.bind(this);
    this.handleNewCauseSubmit = this.handleNewCauseSubmit.bind(this);
    this.setScreenSize = this.setScreenSize.bind(this);
    this.catchDataLoadingError = this.catchDataLoadingError.bind(this);

    this.searchTimeout = null;
    this.toastOpenTimeout = null;
    this.toastUpdateTimeout = null;

    this.state = {
      pageNum: 0,
      causeList: [],
      numPages: 0,
      pageSize: 0,
      sorted: [],

      loadingScreen: true,

      selectedCause: {},

      screenSize: "large",

      showConfirmationModal: false,

      causeSearchTerm: "",
      causeDistance: 10,
      distanceDisabled: false,

      causeTableloading: false,

      isNewCause: false,

      isStoreZip: false,

      loggedIn: false,
      submissionSuccess: false,

      loginResponse: {},
      causeSubmissionResponse: {},

      // Form Fields //

      nameVal: "",
      emailVal: "",
      zipCodeVal: "",
      selectedDate: getDateString(new Date()),
      selectedBanner: 0,
      selectedState: 0,
      selectedStore: 0,

      tags: [],
      tagInput: "",

      /// ///////////////

      states: [],
      banners: [],
      stores: [],
      storesMaster: [],

      /// ///////////////

      causeSubmissionForm: {
        CauseName: "",
        CauseCategoryID: "",
        CauseCategoryObj: "",
        CauseAddress1: "",
        CauseAddress2: "",
        CauseCity: "",
        CauseState: "",
        CauseStateObj: "",
        CauseZip: "",
        CausePhone: "",
        CauseWebsite: "",
        CauseFirstName: "",
        CauseLastName: "",
        CauseTitle: "",
        CauseEmail: "",
        CauseID: 0,
        UserName: "",
        UserEmail: "",
      },

      showModal: false,
      modalContent: [],
      showToast: false,
      toastText: "",
      toastUpdate: false,

      initialLoading: {},

      shouldHideFields: true,

      errorState: {
        errorHasOccured: false,
        isOutdatedBrowser: false,
        dataFailed: false,
      },
    };
  }

  componentDidMount() {
    /**
     * documentMode is an IE-only property
     * http://msdn.microsoft.com/en-us/library/ie/cc196988(v=vs.85).aspx
     */
    const msie = document.documentMode;

    ReactGA.initialize([
      {
        trackingId: this.props.sessionInfo.gaID,
      },
      {
        trackingId: "UA-135614094-2",
        gaOptions: {
          name: "globalTracker",
        },
      },
    ]);

    if (msie && msie < 10) {
      // set error if IE version less than 10
      this.setState({
        errorState: {
          ...this.state.errorState,
          errorHasOccured: true,
          isOutdatedBrowser: true,
        },
      });
      ReactGA.event({
        category: "Error",
        action: "Outdated Browser Error",
      });
    } else {
      const params = queryString.parse(window.location.search);
      if ("mode" in params && params.mode.toLowerCase() === "test") {
        testMode = true;
        this.setState({
          causeSubmissionForm: {
            ...this.state.causeSubmissionForm,
            CauseName: "Test Cause",
            CauseAddress1: "1234 Main St.",
            CauseCity: "Springfield",
            CauseZip: "02478",
            UserName: "Oz User",
            UserEmail: "chriss@psitmatters.com",
          },
        });
      }
      this.setScreenSize();
      window.addEventListener("resize", this.setScreenSize);
      if (this.props.CDDPresent) {
        console.log("cdd present");
        console.log(this.props.CDDUI);
        this.postLogin();
      } else {
        this.loadSelectFields();
      }
      this.checkLoadingDone();
    }
  }

  loadSelectFields = () => {
    const loadingState = {};
    const {variableFields} = this.props.sessionInfo;

    for (const field in variableFields) {
      if (variableFields[field] === true) {
        loadingState[`${field  }Loading`] = true;
      }
    }

    this.setState({
      initialLoading: loadingState,
    });
    if (variableFields.banners === true) {
      this.getBannerList();
    }
    if (variableFields.states === true) {
      this.getStateList();
    }
    if (variableFields.stores === true) {
      this.getStoreList();
    }
  };

  catchDataLoadingError(e) {
    console.log("Catch Data Loading Error:", e);
    this.setState({
      errorState: {
        ...this.state.errorState,
        errorHasOccured: true,
        dataFailed: true,
      },
    });
    ReactGA.event({
      category: "Error",
      action: "Server request response error",
    });
  }

  setScreenSize() {
    const width = window.innerWidth;
    if (width >= 950) {
      this.setState({
        screenSize: "large",
        pageSize: 15,
      });
    } else if (width >= 600) {
      this.setState({
        screenSize: "medium",
        pageSize: 15,
      });
    } else {
      this.setState({
        screenSize: "small",
        pageSize: 10,
      });
    }
  }

  //  HOME PAGE FUNCTIONS

  getBannerList() {
    this.setState({
      loadingScreen: true,
    });
    API.get(`/Store/${  this.props.sessionInfo.bannersURL}`, {
      params: {
        accountUID: this.props.sessionInfo.accountUID,
      },
    })
      .then((res) => {
        const output = res.data.banners.map((el) => {
          return {
            value: el.BannerID,
            label: el.Banner_Name,
          };
        });
        const newState = {
          banners: output,
          initialLoading: {
            ...this.state.initialLoading,
            bannersLoading: false,
          },
        };
        if (testMode) newState.selectedBanner = output[0];
        this.setState(newState, () => {
          this.checkLoadingDone();
        });
      })
      .catch(this.catchDataLoadingError);
  }

  getStateList() {
    this.setState({
      loadingScreen: true,
    });
    API.get(`/Store/${  this.props.sessionInfo.statesURL}`, {
      params: {
        accountUID: this.props.sessionInfo.accountUID,
      },
    })
      .then((res) => {
        const output = res.data.sStates.map((el) => {
          return {
            value: el.State_Code,
            label: `${el.State_Name} (${el.State_Code})`,
          };
        });
        const newState = {
          states: output,
          initialLoading: {
            ...this.state.initialLoading,
            statesLoading: false,
          },
        };
        if (testMode) newState.selectedState = output[0];
        this.setState(newState, () => {
          this.checkLoadingDone();
        });
      })
      .catch(this.catchDataLoadingError);
  }

  getStoreList() {
    API.get(`/Store/${  this.props.sessionInfo.storesURL}`, {
      params: {
        accountUID: this.props.sessionInfo.accountUID,
        bID: -1,
        state_Code: "ZZ",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data.stores);
          const template = _.template(this.props.sessionInfo.addressTemplate);
          const output = res.data.stores.map((el) => {
            return {
              label: template({
                state: el.CompanyState,
                city: el.CompanyCity,
                address: el.CompanyAddress,
              }),
              value: el.CompanyUID,
              BannerID: el.BannerID,
              State: el.CompanyState,
              zipcode: el.CompanyZip,
            };
          });
          const newState = {
            storesMaster: output,
            stores: output,
            initialLoading: {
              ...this.state.initialLoading,
              storesLoading: false,
            },
          };
          if (testMode) newState.selectedStore = output[0];
          this.setState(newState, () => {
            this.checkLoadingDone();
          });
        }
      })
      .catch(this.catchDataLoadingError);
  }

  checkLoadingDone() {
    let loadingDone = true;
    const loadingObj = this.state.initialLoading;

    for (const key in loadingObj) {
      if (loadingObj[key] === true) {
        loadingDone = false;
        break;
      }
    }

    if (loadingDone) {
      const newState = {
        loadingScreen: false,
      };

      if (testMode) {
        const params = queryString.parse(window.location.search);
        if ("cycle" in params && Number(params.cycle) === 2) {
          newState.tagInput = "VAL-IDB";
        } else {
          newState.tagInput = "VAL-IDA";
        }
      }

      this.setState(newState);
    }
  }

  onBannerSelect(banner) {
    if (banner.value !== this.state.selectedBanner.value) {
      const newStoreList = this.state.storesMaster.filter((store) => {
        return store.BannerID === banner.value;
      });

      this.setState({
        selectedBanner: banner,
        selectedStore: {},
        stores: newStoreList,
      });
    }
  }

  onStateSelect(state) {
    if (state.value !== this.state.selectedState.value) {
      const newStoreList = this.state.storesMaster.filter((store) => {
        return store.State === state.value;
      });

      this.setState({
        selectedState: state,
        selectedStore: {},
        stores: newStoreList,
      });
    }
  }

  onStoreSelect(store) {
    this.setState({
      selectedStore: store,
    });
  }

  onNameChange(e) {
    this.setState({
      nameVal: e.target.value,
    });
  }

  onEmailChange(e) {
    this.setState({
      emailVal: e.target.value,
    });
  }

  onZipCodeChange(e) {
    this.setState({
      zipCodeVal: e.target.value,
    });
  }

  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      selectedDate: getDateString(day),
    });
  }

  addTag() {
    const {tagInput} = this.state;

    if (tagInput.length === 0) {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i))
        document.activeElement.blur();
      return { success: false, error: "The code field is blank" };
    }

    if (
      tagInput.replace(/[-_]/g, "").length ===
      this.props.pageContent.tagDetails.length
    ) {
      const tagStr = tagInput.toUpperCase();
      if (this.tagIsNew(tagStr)) {
        this.setState((prevState) => ({
          tags: [...prevState.tags, { tag: tagStr, valid: true }],
          tagInput: this.props.sessionInfo.singleTag ? tagInput : "",
        }));
        return { success: true, error: "" };
      } 
        if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i))
          document.activeElement.blur();
        return { success: false, error: "You have already added this code" };
      
    } 
      if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i))
        document.activeElement.blur();
      return { success: false, error: "The format of this code is invalid" };
    
  }

  tagIsNew(tagStr) {
    let isNew = true;
    const {tags} = this.state;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].tag === tagStr) {
        isNew = false;
        break;
      }
    }
    return isNew;
  }

  removeTag(e) {
    const tagArr = this.state.tags;
    tagArr.splice(e.target.getAttribute("data-index"), 1);
    this.setState({
      tags: tagArr,
    });
  }

  handleTagInputChange(e) {
    this.setState({ tagInput: e.target.value });
  }

  handleSubmitClick(e) {
    e.preventDefault();

    ReactGA.event({
      category: "Click",
      action: "go to non-Profit selection click",
    });

    const formValidation = this.validateForm();
    if (formValidation.success) {
      const addTagAttempt = this.addTag();
      if (
        (this.state.tags.length === 0 || this.state.tagInput.length !== 0) &&
        addTagAttempt.success === false
      ) {
        this.openToast(addTagAttempt.error);
      } else {
        setTimeout(() => this.postLogin());
      }
    } else {
      this.setState({
        modalContent: formValidation.errors,
      });
      this.openModal();
      ReactGA.event({
        category: "Error",
        action: "clientside login fields error",
      });
    }
  }

  postLogin() {
    this.setState({
      loadingScreen: true,
    });
    if (this.props.CDDPresent) {
      API.post("/User/CDDLogin", {
        AccountUID: this.props.sessionInfo.accountUID,
        Application: this.props.sessionInfo.singleTag
          ? "SingleTag"
          : "MultiTags",
        CDDCode: "",
        CDDUID: this.props.CDDUI,
        Coupons: "",
        ProgramUID: this.props.sessionInfo.programUID,
        UserEmail: this.state.emailVal,
        ZipCode: this.state.zipCodeVal,
      })
        .then((res) => {
          if (res.data.ValidLogin) {
            this.setState({
              loggedIn: true,
              loginResponse: res.data,
            });
            resetScroll();
          } else {
            this.props.cancelCDD();
            this.loadSelectFields();
            res.data.Response = res.data.Response.replace(/<br\/>/gi, " ");
            this.openLoginErrorModal(res.data);
          }

          this.setState({
            loadingScreen: false,
          });
          ReactGA.event({
            category: "Login",
            action: "successful login with CDDS",
          });
        })
        .catch(this.catchDataLoadingError);
    } else {
      API.post("/User/CDDLogin", {
        AccountUID: this.props.sessionInfo.accountUID,
        Application: this.props.sessionInfo.singleTag
          ? "SingleTag"
          : "MultiTags",
        CDDCode: this.props.sessionInfo.singleTag
          ? this.state.tags[0].tag.replace(/-/g, "").toLowerCase()
          : "",
        Coupons: this.props.sessionInfo.singleTag
          ? []
          : this.state.tags.map((tag) => ({
              CouponCode: tag.tag.replace(/-/g, "").toLowerCase(),
            })),
        FullName: this.state.nameVal,
        ProgramUID: this.props.sessionInfo.programUID,
        PurchaseDate: this.state.selectedDate,
        PurchaseLocation: this.state.selectedStore.value,
        UserEmail: this.state.emailVal,
        ZipCode:
          this.state.zipCodeVal.length > 0
            ? this.state.zipCodeVal
            : this.state.selectedStore.zipcode || "",
      })
        .then((res) => {
          if (res.data.ValidLogin) {
            this.setState({
              loginResponse: res.data,
              loggedIn: true,
            });
            if (this.state.zipCodeVal.length === 0) {
              this.setState({
                isStoreZip: true,
              });
            }
            resetScroll();
          } else {
            this.openLoginErrorModal(res.data);
          }
          this.setState({
            loginResponse: res.data,
            loadingScreen: false,
          });
          ReactGA.event({
            category: "Login",
            action: "logged in with new tags",
          });
        })
        .catch(this.catchDataLoadingError);
    }
  }

  openLoginErrorModal(data) {
    const errors = [<span className="error-heading">{data.Response}</span>];
    if (data.Coupons) {
      data.Coupons.forEach((el) => {
        if (el.ValidCoupon === false) {
          errors.push(el.Response);
        }
      });
    }
    const newTags = this.props.sessionInfo.singleTag
      ? []
      : this.state.tags.map((tag, i) => {
          tag.valid = data.Coupons[i].ValidCoupon;
          return tag;
        });
    this.setState({
      modalContent: errors,
      tags: newTags,
    });
    this.openModal();
    ReactGA.event({
      category: "Error",
      action: "server-side login error",
    });
  }

  // SELECTION PAGE FUNCTIONS

  getCauseList() {
    const {pageSize} = this.state;
    const page = this.state.pageNum;
    const {sorted} = this.state;

    this.setState({ causeTableLoading: true });
    let mysql =
      `select * from (select *,[dbo].[UDF_Distance](latitude,longitude,${ 
      this.state.loginResponse.Latitude 
      },${ 
      this.state.loginResponse.Longitude 
      }) as causedistance from ${ 
      this.props.sessionInfo.causeView 
      }) as a where 1=1`;
    if (this.state.causeSearchTerm !== "") {
      mysql +=
        ` and (causename like '%${ 
        this.state.causeSearchTerm.replace("'", "''") 
        }%' or causecity like '${ 
        this.state.causeSearchTerm.replace("'", "''") 
        }%')`;
    } else {
      mysql +=
        ` and latitude not in (0,1) and causeDistance <= ${ 
        this.state.causeDistance}`;
    }

    const params = {
      userUID: "",
      accountUID: this.props.sessionInfo.accountUID,
      programUID: "",
      sqlStatement: mysql,
      lat: this.state.loginResponse.Latitude,
      lng: this.state.loginResponse.Longitude,
      distance: this.state.causeDistance,
      filter: "",
      page,
      limit: pageSize,
      start: page * pageSize,
    };

    if (sorted && sorted.length) {
      const arr = [];
      arr.push({
        property: sorted[0].id,
        direction: sorted[0].desc ? "DESC" : "ASC",
      });
      params.sort =
        `[{"property": "${ 
        sorted[0].id === "CauseLocation" ? "CauseCity" : sorted[0].id 
        }", "direction": ${ 
        sorted[0].desc ? '"DESC"' : '"ASC"' 
        }}]`;
    }

    API.get("/Cause/CDDSCauses", {
      params,
    })
      .then((res) => {
        const causeList = res.data.causes.map((cause) => {
          cause.CauseLocation = `${cause.CauseCity  }, ${  cause.CauseState}`;
          cause.CauseDistance = cause.CauseDistance.toFixed(1);
          return cause;
        });
        this.setState({
          causeList,
          numPages: Math.ceil(res.data.totalCount / pageSize),
          causeTableLoading: false,
        });
      })
      .catch(this.catchDataLoadingError);
  }

  updateTable(pageSize, page, sorted) {
    this.setState(
      {
        pageSize,
        pageNum: page,
        sorted,
      },
      this.getCauseList
    );
  }

  onSearchChange(e) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.getCauseList, 475);

    this.setState({
      causeSearchTerm: e.target.value,
      distanceDisabled: !!e.target.value,
      pageNum: 0,
    });
  }

  distanceUpdated(newVal) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(this.getCauseList, 475);
    this.setState({
      causeDistance: newVal,
      pageNum: 0,
    });
  }

  handleNewCauseSubmit(e) {
    e.preventDefault();

    const formValidation = this.validateNewCauseForm();
    if (formValidation.success) {
      this.setState(
        {
          isNewCause: true,
          selectedCause: this.state.causeSubmissionForm,
          loginResponse: {
            ...this.state.loginResponse,
            FullName: this.state.causeSubmissionForm.UserName,
            UserEmail: this.state.causeSubmissionForm.UserEmail,
          },
        },
        () => {
          this.handleCauseSubmit();
        }
      );
    } else {
      this.setState({
        modalContent: formValidation.errors,
      });
      this.openModal();
      ReactGA.event({
        category: "Error",
        action: "clientside non-profit submission error",
      });
    }
  }

  handleCauseClick(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          this.setState({ selectedCause: rowInfo.original });
          this.openConfirmationModal();
        },
        style: {
          cursor: "pointer",
        },
      };
    } 
      return {};
    
  }

  handleCauseSubmit() {
    this.setState({
      loadingScreen: true,
    });
    const CDDUID = this.props.CDDPresent ? this.props.CDDUI : "";
    this.closeConfirmationModal();
    API.post("/User/CDDSubmitNonProfit", {
      AccountUID: this.state.loginResponse.AccountUID,
      Application: this.props.sessionInfo.singleTag ? "SingleTag" : "MultiTags",
      CDDCode: this.props.sessionInfo.singleTag
        ? this.state.tags[0].tag.replace(/-/g, "").toLowerCase()
        : "",
      Coupons: this.props.sessionInfo.singleTag
        ? []
        : this.state.tags.map((tag) => ({
            CouponCode: tag.tag.replace(/-/g, "").toLowerCase(),
          })),
      CDDUID,
      distance: this.state.loginResponse.Distance,
      FullName:
        this.state.loginResponse.FullName.length === 0
          ? "Anonymous"
          : this.state.loginResponse.FullName,
      ProgramUID: this.state.loginResponse.ProgramUID,
      PurchaseDate: this.state.loginResponse.PurchaseDate,
      UserEmail: this.state.loginResponse.UserEmail,
      ZipCode: this.state.loginResponse.ZipCode,
      ValidLogin: this.state.loginResponse.ValidLogin,
      selectedCause: this.state.selectedCause,
      CompanyUID: this.state.selectedStore.value,
    })
      .then((res) => {
        if (res.data.ValidLogin) {
          this.setState({
            submissionSuccess: true,
          });
          ReactGA.event({
            category: "Non-profit submission",
            action: "successful non-profit submission",
            label: this.state.isNewCause
              ? "new non-profit"
              : "existing non-profit",
          });
        } else {
          this.openLoginErrorModal(res.data);
          this.setState({
            loggedIn: false,
          });
          ReactGA.event({
            category: "Error",
            action: "server-side error during non-profit submission",
            label: this.state.isNewCause
              ? "new non-profit"
              : "existing non-profit",
          });
        }
        this.setState({
          loginResponse: res.data,
          causeSubmissionResponse: res.data,
          loadingScreen: false,
        });
      })
      .catch(this.catchDataLoadingError);
  }

  openToast(text) {
    this.setState({
      toastText: text,
      showToast: true,
    });
    if (this.state.showToast) {
      this.updateToast();
    }
    clearTimeout(this.toastOpenTimeout);
    this.toastOpenTimeout = setTimeout(() => this.closeToast(), 2500);
    ReactGA.event({
      category: "Error",
      action: "Tag Adding Error",
    });
  }

  closeToast() {
    this.setState({
      toastText: "",
      showToast: false,
    });
  }

  updateToast() {
    this.setState({
      toastUpdate: true,
    });
    clearTimeout(this.toastUpdateTimeout);
    this.toastUpdateTimeout = setTimeout(() => this.endUpdateToast(), 250);
  }

  endUpdateToast() {
    this.setState({
      toastUpdate: false,
    });
  }

  updateCauseSubmissionForm(e) {
    this.setState({
      causeSubmissionForm: {
        ...this.state.causeSubmissionForm,
        [e.target.name]: e.target.value,
      },
    });
  }

  updateSelectedState(causeState) {
    this.setState({
      causeSubmissionForm: {
        ...this.state.causeSubmissionForm,
        CauseStateObj: causeState,
        CauseState: causeState.value,
      },
    });
  }

  updateSelectedCategory(category) {
    this.setState({
      causeSubmissionForm: {
        ...this.state.causeSubmissionForm,
        CauseCategoryObj: category,
        CauseCategoryID: category.value,
      },
    });
  }

  validateForm() {
    let formValid = true;
    const errors = [
      <span className="error-heading">
        Please check the form and try again
      </span>,
    ];

    if (this.state.shouldHideFields) {
      if (
        (this.props.sessionInfo.zipCodeRequired ||
          this.state.zipCodeVal.length !== 0) &&
        !isZipCode(this.state.zipCodeVal)
      ) {
        formValid = false;
        errors.push("Make sure you enter a valid 5 digit zip code");
      }
    } else {
      if (!isName(this.state.nameVal)) {
        formValid = false;
        errors.push("Please provide your full name");
      }
      if (!isEmail(this.state.emailVal)) {
        formValid = false;
        errors.push("Please provide a valid email address");
      }

      if (!isZipCode(this.state.zipCodeVal)) {
        formValid = false;
        errors.push("Make sure you enter a valid 5 digit zip code");
      }
    }

    if (this.props.sessionInfo.variableFields.dateField) {
      if (!isDate(this.state.selectedDate)) {
        formValid = false;
        errors.push("Please use the calendar to select a valid date");
      }
    }

    if (this.props.sessionInfo.variableFields.stores) {
      if (!isSelected(this.state.selectedStore.value)) {
        formValid = false;

        if (this.props.sessionInfo.variableFields.banners) {
          errors.push("Select a chain and store location");
        } else if (this.props.sessionInfo.variableFields.states) {
          errors.push("Select a state and store location");
        }
      }
    }
    if (formValid) {
      return {
        success: true,
        errors: [],
      };
    } 
      return {
        success: false,
        errors,
      };
    
  }

  validateNewCauseForm() {
    let formValid = true;
    const errors = [];
    if (this.state.shouldHideFields) {
      if (!isName(this.state.causeSubmissionForm.UserName)) {
        formValid = false;
        errors.push("Please provide your name");
      }

      if (!isEmail(this.state.causeSubmissionForm.UserEmail)) {
        formValid = false;
        errors.push("Please provide an email we can use to contact you");
      }
    }

    if (!isName(this.state.causeSubmissionForm.CauseName)) {
      formValid = false;
      errors.push("Please provide a name for the non-profit");
    }
    if (!isSelected(this.state.causeSubmissionForm.CauseCategoryID)) {
      formValid = false;
      errors.push("Please select a category");
    }
    if (!isName(this.state.causeSubmissionForm.CauseAddress1)) {
      formValid = false;
      errors.push("Enter a valid address for the non-profit");
    }
    if (!isName(this.state.causeSubmissionForm.CauseCity)) {
      formValid = false;
      errors.push("Enter the city the non-profit is located in");
    }
    if (!isSelected(this.state.causeSubmissionForm.CauseState)) {
      formValid = false;
      errors.push("Select a state in which the non-profit is based");
    }
    if (!isZipCode(this.state.causeSubmissionForm.CauseZip)) {
      formValid = false;
      errors.push("Enter a valid 5-digit zip code for the non-profit");
    }
    if (formValid) {
      return {
        success: true,
        errors: [],
      };
    } 
      return {
        success: false,
        errors,
      };
    
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  closeConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  handlePageChange(e) {
    this.setState({ pageNum: e });
  }

  render() {
    if (this.state.errorState.errorHasOccured) {
      return (
        <ErrorScreen
          errorState={this.state.errorState}
          contactInfo={this.props.pageContent.contactInfo}
        />
      );
    }

    return (
      <div
        className={
          `App${  this.props.sessionInfo.account === "sas" ? " sas" : ""}`
        }
      >
        <LoadingScreen loadingScreen={this.state.loadingScreen} />

        <Header
          pageContent={this.props.pageContent}
          sessionInfo={this.props.sessionInfo}
        />

        {this.state.submissionSuccess ? (
          <ThankYou
            loginResponse={this.state.loginResponse}
            selectedCause={this.state.selectedCause}
            isNewCause={this.state.isNewCause}
            title={this.props.pageContent.subTitle}
          />
        ) : this.state.loggedIn ? (
          <Selection
            pageContent={this.props.pageContent}
            sessionInfo={this.props.sessionInfo}
            loginResponse={this.state.loginResponse}
            getCauseList={this.getCauseList}
            data={this.state.causeList}
            numPages={this.state.numPages}
            loading={this.state.causeTableLoading}
            causeSearchTerm={this.state.causeSearchTerm}
            causeDistance={this.state.causeDistance}
            distanceDisabled={this.state.distanceDisabled}
            distanceUpdated={this.distanceUpdated}
            onSearchChange={this.onSearchChange}
            handlePageChange={this.handlePageChange}
            handleCauseClick={this.handleCauseClick}
            updateTable={this.updateTable}
            causeSubmissionForm={this.state.causeSubmissionForm}
            updateCauseSubmissionForm={this.updateCauseSubmissionForm}
            updateSelectedCategory={this.updateSelectedCategory}
            updateSelectedState={this.updateSelectedState}
            handleNewCauseSubmit={this.handleNewCauseSubmit}
            screenSize={this.state.screenSize}
            pageNum={this.state.pageNum}
            pageSize={this.state.pageSize}
            sorted={this.state.sorted}
            CDDPresent={this.props.CDDPresent}
            selectedChain={this.state.selectedBanner.label}
            shouldHideFields={this.state.shouldHideFields}
            isStoreZip={this.state.isStoreZip}
          />
        ) : (
          <Home
            tags={this.state.tags}
            addTag={this.addTag}
            removeTag={this.removeTag}
            handleDayClick={this.handleDayClick}
            zipCodeVal={this.state.zipCodeVal}
            onZipCodeChange={this.onZipCodeChange}
            nameVal={this.state.nameVal}
            onNameChange={this.onNameChange}
            emailVal={this.state.emailVal}
            onEmailChange={this.onEmailChange}
            onBannerSelect={this.onBannerSelect}
            banners={this.state.banners}
            onStoreSelect={this.onStoreSelect}
            stores={this.state.stores}
            onStateSelect={this.onStateSelect}
            states={this.state.states}
            pageContent={this.props.pageContent}
            selectedStore={this.state.selectedStore}
            selectedBanner={this.state.selectedBanner}
            selectedDate={this.state.selectedDate}
            handleSubmitClick={this.handleSubmitClick}
            openToast={this.openToast}
            tagInput={this.state.tagInput}
            handleTagInputChange={this.handleTagInputChange}
            sessionInfo={this.props.sessionInfo}
            shouldHideFields={this.state.shouldHideFields}
          />
        )}

        <Footer pageContent={this.props.pageContent} />

        <Modal
          hidden={!this.state.showModal}
          content={this.state.modalContent}
          closeModal={this.closeModal}
        />

        <ConfirmationModal
          hidden={!this.state.showConfirmationModal}
          content={this.state.selectedCause}
          closeModal={this.closeConfirmationModal}
          loginResponse={this.state.loginResponse}
          handleCauseSubmit={this.handleCauseSubmit}
        />

        <Toast
          hidden={!this.state.showToast}
          text={this.state.toastText}
          update={this.state.toastUpdate}
        />
      </div>
    );
  }
}

export default App;
