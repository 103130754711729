import React from "react";
import "./LoadingScreen.scss";

const LoadingScreen = props => {
  return (
    <div className={`loading ${  props.loadingScreen ? "" : "hidden"}`}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingScreen;
