import React from "react";
import MaskedInput from "react-text-mask";
import "./TagField.scss";

const TagField = ({
  addTag,
  openToast,
  tagDetails,
  sessionInfo,
  tagInput,
  handleTagInputChange,
  tags,
  removeTag,
}) => {
  const handleAddClick = (e) => {
    e.preventDefault();
    const response = addTag();
    if (!response.success) {
      openToast(response.error);
    }
  };

  return (
    <div id="tag-field">
      <div className="tag-input-container">
        <MaskedInput
          mask={tagDetails.mask}
          className="tag-input"
          placeholder={tagDetails.placeholder}
          guide={false}
          value={tagInput}
          onChange={handleTagInputChange}
          style={{ width: sessionInfo.singleTag ? "100%" : "9.5rem" }}
        />
        {!sessionInfo.singleTag && (
          <button
            type="button"
            className={`btn btn-outline-primary btn-outline-${sessionInfo.account}`}
            onClick={handleAddClick}
          >
            Add Another Code
          </button>
        )}
      </div>
      {!sessionInfo.singleTag && tags.length > 0 && (
        <div className="tag-container">
          <div className="tag-container-header">Your Current Tags</div>
          <div className="tag-container-body">
            {tags.map((tag, i) => {
              return (
                <span
                  className={`tag-icon${tag.valid ? " valid" : " invalid"}`}
                  key={tag.tag + i}
                >
                  <span className="tag-text">{tag.tag}</span>
                  <span
                    className="close-icon"
                    data-index={i}
                    onClick={removeTag}
                  >
                    &#10006;
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagField;
