import React, { Component } from "react";
import Select from "react-select";
import "./Home.scss";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { formatDate, parseDate } from "react-day-picker/moment";

import TagField from "../TagField";

export default class Home extends Component {
  constructor(props) {
    super(props);
    const earliest = new Date();
    earliest.setDate(earliest.getDate() - 7);
    const latest = new Date();

    this.state = {
      disabledDays: [
        {
          after: latest,
          before: earliest,
        },
      ],
    };
  }

  render() {
    return (
      <div id="home-page">
        <div className="body-content">
          <div className="top-content">
            <div className="image-container">
              <a
                rel="noopener noreferrer"
                href={this.props.pageContent.contactInfo.subsiteURL}
                target="_blank"
              >
                <img
                  className="bag-image"
                  src={`${process.env.PUBLIC_URL}/images/${this.props.sessionInfo.account}/bag.png`}
                  alt=""
                />
              </a>
            </div>

            <h2 style={{ color: this.props.pageContent.style.primaryColor }}>
              How it Works
            </h2>
            {this.props.pageContent.introParagraphs}
          </div>

          <form>
            {!this.props.shouldHideFields && (
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  value={this.props.nameVal}
                  onChange={this.props.onNameChange}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter full name"
                  required
                />
              </div>
            )}
            {!this.props.shouldHideFields && (
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  value={this.props.emailVal}
                  onChange={this.props.onEmailChange}
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  required
                />
              </div>
            )}

            {this.props.sessionInfo.variableFields.dateField && (
              <div className="form-group">
                <label htmlFor="datePurchased">
                  Date purchased{" "}
                  <span className="optional">(click to change)</span>
                </label>
                <DayPickerInput
                  placeholder="mm/dd/yyyy"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  value={this.props.selectedDate}
                  required
                  dayPickerProps={{
                    onDayClick: this.props.handleDayClick,
                    disabledDays: this.state.disabledDays,
                  }}
                />
              </div>
            )}

            {this.props.sessionInfo.variableFields.banners && (
              <div className="form-group">
                <label htmlFor="banner">Store chain purchased from</label>
                <Select
                  value={this.props.selectedBanner}
                  onChange={this.props.onBannerSelect}
                  options={this.props.banners}
                  isSearchable={false}
                  name="banner-select"
                />
              </div>
            )}

            {this.props.sessionInfo.variableFields.states && (
              <div className="form-group">
                <label htmlFor="banner">State where purchase was made</label>
                <Select
                  value={this.props.selectedState}
                  onChange={this.props.onStateSelect}
                  options={this.props.states}
                  isSearchable={false}
                  name="state-select"
                />
              </div>
            )}

            {this.props.sessionInfo.variableFields.stores && (
              <div className="form-group">
                <label htmlFor="storeLocation">
                  Location of store purchased from
                </label>
                <Select
                  value={this.props.selectedStore}
                  onChange={this.props.onStoreSelect}
                  options={this.props.stores}
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="zipCode">
                Your US Zip Code{" "}
                {this.props.shouldHideFields &&
                  !this.props.sessionInfo.zipCodeRequired && (
                    <span className="optional">(optional)</span>
                  )}
              </label>
              <input
                value={this.props.zipCodeVal}
                onChange={this.props.onZipCodeChange}
                type="text"
                className="form-control"
                id="zipCode"
                placeholder="Find non-profits local to you"
                required
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>

            <div className="form-group">
              <label htmlFor="tags">
                {this.props.pageContent.customTagLabel ? (
                  this.props.pageContent.customTagLabel
                ) : (
                  <span>
                    Enter the {this.props.pageContent.tagDetails.length}-letter
                    code
                    {!this.props.sessionInfo.singleTag && "s"} that appear on
                    your{" "}
                    {this.props.pageContent.isSticker ? "stickers" : "tags"}.{" "}
                    <b>There are no numbers.</b>
                  </span>
                )}
              </label>
              <TagField
                pageContent={this.props.pageContent}
                tags={this.props.tags}
                addTag={this.props.addTag}
                removeTag={this.props.removeTag}
                openToast={this.props.openToast}
                tagInput={this.props.tagInput}
                handleTagInputChange={this.props.handleTagInputChange}
                tagDetails={this.props.pageContent.tagDetails}
                sessionInfo={this.props.sessionInfo}
              />
            </div>

            <div className="button-container">
              <button
                onClick={this.props.handleSubmitClick}
                className={`btn btn-lg btn-${  this.props.sessionInfo.account}`}
                name="submit-button"
              >
                Go to Non-Profit Selection
              </button>
            </div>

            <div
              className="privacy"
              style={{ color: this.props.pageContent.style.primaryColor }}
            >
              {this.props.pageContent.privacy}
            </div>
          </form>
          <p className="bottom-statement">
            {this.props.pageContent.bottomStatement}
          </p>
        </div>
      </div>
    );
  }
}
